* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  margin-right: 1em;
}

.out {
  margin-top: 5px;
  padding: 10em;
  border: 2px dashed #ddd;
  border-radius: 0.5em;
}
